import _buttonHelper from "../buttonHelper";
import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
import _Button from "../Button";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModalIframe = exports.ModalMessage = exports.Modal = exports.isMessageModal = exports.isIframeModal = exports.data = exports.update = exports.clickFooterButton = exports.updateModalSize = exports.closeModal = exports.openModal = exports.Size = exports.Action = void 0;
var buttonHelper_1 = _buttonHelper;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var Button_1 = _Button;
var Action;
(function (Action) {
  Action["OPEN"] = "APP::MODAL::OPEN";
  Action["CLOSE"] = "APP::MODAL::CLOSE";
  Action["UPDATE"] = "APP::MODAL::UPDATE";
  Action["UPDATE_CONTENT"] = "APP::MODAL::CONTENT::UPDATE";
  Action["FOOTER_BUTTON_CLICK"] = "APP::MODAL::FOOTER::BUTTON::CLICK";
  Action["FOOTER_BUTTON_UPDATE"] = "APP::MODAL::FOOTER::BUTTON::UPDATE";
  Action["UPDATE_SIZE"] = "APP::MODAL::UPDATE_SIZE";
  Action["DATA"] = "APP::MODAL::DATA";
})(Action = exports.Action || (exports.Action = {}));
/**
 * Options available to the Modal `size` param
 * @public
 */
var Size;
(function (Size) {
  /** Small modal size */
  Size["Small"] = "small";
  /** Medium modal size */
  Size["Medium"] = "medium";
  /** Large modal size (wider than medium) */
  Size["Large"] = "large";
  /** @deprecated as of 1.6.5 */
  Size["Full"] = "full";
  /**
   * @deprecated as of 1.12.x
   * @remarks
   * This option has been removed in favour of the `setUpModalAutoSizing` utility.
   */
  Size["Auto"] = "auto";
})(Size = exports.Size || (exports.Size = {}));
var FOOTER_BUTTON_PROPS = {
  group: types_1.Group.Modal,
  subgroups: ["Footer"],
  type: types_1.ComponentType.Button
};
function openModal(modalPayload) {
  return helper_1.actionWrapper({
    group: types_1.Group.Modal,
    payload: modalPayload,
    type: Action.OPEN
  });
}
exports.openModal = openModal;
function closeModal(modalClosePayload) {
  return helper_1.actionWrapper({
    group: types_1.Group.Modal,
    payload: modalClosePayload,
    type: Action.CLOSE
  });
}
exports.closeModal = closeModal;
/**
 * Action creator for modal update size action
 * @internal
 */
function updateModalSize(updateSizePayload) {
  return helper_1.actionWrapper({
    group: types_1.Group.Modal,
    payload: updateSizePayload,
    type: Action.UPDATE_SIZE
  });
}
exports.updateModalSize = updateModalSize;
function clickFooterButton(id, payload) {
  var component = __assign({
    id: id
  }, FOOTER_BUTTON_PROPS);
  return Button_1.clickButton(types_1.Group.Modal, component, payload);
}
exports.clickFooterButton = clickFooterButton;
function update(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Modal,
    type: Action.UPDATE
  });
}
exports.update = update;
function data(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Modal,
    type: Action.DATA
  });
}
exports.data = data;
function isIframeModal(options) {
  return typeof options.url === "string" || typeof options.path === "string";
}
exports.isIframeModal = isIframeModal;
function isMessageModal(options) {
  return typeof options.message === "string";
}
exports.isMessageModal = isMessageModal;
var Modal = /** @class */function (_super) {
  __extends(Modal, _super);
  function Modal() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.size = Size.Small;
    return _this;
  }
  Object.defineProperty(Modal.prototype, "footer", {
    get: function () {
      if (!this.footerPrimary && !this.footerSecondary) {
        return undefined;
      }
      return {
        buttons: {
          primary: this.footerPrimary,
          secondary: this.footerSecondary
        }
      };
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Modal.prototype, "footerOptions", {
    get: function () {
      if (!this.footerPrimaryOptions && !this.footerSecondaryOptions) {
        return undefined;
      }
      return {
        buttons: {
          primary: this.footerPrimaryOptions,
          secondary: this.footerSecondaryOptions
        }
      };
    },
    enumerable: false,
    configurable: true
  });
  Modal.prototype.close = function () {
    this.app.dispatch(closeModal({
      id: this.id
    }));
  };
  Modal.prototype.setFooterPrimaryButton = function (newOptions, updateCb) {
    var _this = this;
    var subgroups = FOOTER_BUTTON_PROPS.subgroups;
    this.footerPrimaryOptions = this.getChildButton(newOptions, this.footerPrimaryOptions);
    this.footerPrimary = this.footerPrimaryOptions ? buttonHelper_1.getSingleButton(this, this.footerPrimaryOptions, subgroups, function (newPayload) {
      _this.updatePrimaryFooterButton(newPayload, updateCb);
    }) : undefined;
  };
  Modal.prototype.setFooterSecondaryButtons = function (newOptions, updateCb) {
    var _this = this;
    var subgroups = FOOTER_BUTTON_PROPS.subgroups;
    var newButtons = newOptions || [];
    var currentOptions = this.footerOptions && this.footerOptions.buttons.secondary || [];
    this.footerSecondaryOptions = this.getUpdatedChildActions(newButtons, currentOptions);
    this.footerSecondary = this.footerSecondaryOptions ? this.footerSecondaryOptions.map(function (action) {
      return buttonHelper_1.getSingleButton(_this, action, subgroups, function (newPayload) {
        _this.updateSecondaryFooterButton(newPayload, updateCb);
      });
    }) : undefined;
  };
  Modal.prototype.getChildButton = function (newAction, currentAction) {
    var newButtons = newAction ? [newAction] : [];
    var currentButtons = currentAction ? [currentAction] : [];
    var updatedButton = this.getUpdatedChildActions(newButtons, currentButtons);
    return updatedButton ? updatedButton[0] : undefined;
  };
  Modal.prototype.updatePrimaryFooterButton = function (newPayload, updateCb) {
    if (!this.footer || !this.footer.buttons.primary) {
      return;
    }
    if (helper_1.updateActionFromPayload(this.footer.buttons.primary, newPayload)) {
      updateCb();
    }
  };
  Modal.prototype.updateSecondaryFooterButton = function (newPayload, updateCb) {
    if (!this.footer || !this.footer.buttons || !this.footer.buttons.secondary) {
      return;
    }
    var updated;
    for (var _i = 0, _a = this.footer.buttons.secondary; _i < _a.length; _i++) {
      var action = _a[_i];
      updated = helper_1.updateActionFromPayload(action, newPayload);
      if (updated) {
        break;
      }
    }
    if (updated) {
      updateCb();
    }
  };
  return Modal;
}(ActionSet_1.ActionSetWithChildren);
exports.Modal = Modal;
var ModalMessage = /** @class */function (_super) {
  __extends(ModalMessage, _super);
  function ModalMessage(app, options) {
    var _this = _super.call(this, app, types_1.Group.Modal, types_1.Group.Modal) || this;
    _this.set(options, false);
    return _this;
  }
  Object.defineProperty(ModalMessage.prototype, "payload", {
    get: function () {
      return __assign(__assign({}, this.options), {
        footer: this.footer,
        id: this.id
      });
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ModalMessage.prototype, "options", {
    get: function () {
      return {
        footer: this.footerOptions,
        message: this.message,
        size: this.size,
        title: this.title
      };
    },
    enumerable: false,
    configurable: true
  });
  ModalMessage.prototype.set = function (options, shouldUpdate) {
    var _this = this;
    if (shouldUpdate === void 0) {
      shouldUpdate = true;
    }
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var title = mergedOptions.title,
      footer = mergedOptions.footer,
      message = mergedOptions.message,
      size = mergedOptions.size;
    this.title = title;
    this.message = message;
    this.size = size;
    this.setFooterPrimaryButton(footer ? footer.buttons.primary : undefined, function () {
      _this.dispatch(Action.UPDATE);
    });
    this.setFooterSecondaryButtons(footer ? footer.buttons.secondary : undefined, function () {
      _this.dispatch(Action.UPDATE);
    });
    if (shouldUpdate) {
      this.dispatch(Action.UPDATE);
    }
    return this;
  };
  ModalMessage.prototype.dispatch = function (action) {
    switch (action) {
      case Action.OPEN:
        this.app.dispatch(openModal(this.payload));
        break;
      case Action.CLOSE:
        this.close();
        break;
      case Action.UPDATE:
        this.app.dispatch(update(this.payload));
        break;
    }
    return this;
  };
  return ModalMessage;
}(Modal);
exports.ModalMessage = ModalMessage;
var ModalIframe = /** @class */function (_super) {
  __extends(ModalIframe, _super);
  function ModalIframe(app, options) {
    var _this = _super.call(this, app, types_1.Group.Modal, types_1.Group.Modal) || this;
    _this.set(options, false);
    return _this;
  }
  Object.defineProperty(ModalIframe.prototype, "payload", {
    get: function () {
      return __assign(__assign({}, this.options), {
        footer: this.footer,
        id: this.id
      });
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ModalIframe.prototype, "options", {
    get: function () {
      return {
        footer: this.footerOptions,
        path: this.path,
        size: this.size,
        title: this.title,
        url: this.url,
        loading: this.loading
      };
    },
    enumerable: false,
    configurable: true
  });
  ModalIframe.prototype.set = function (options, shouldUpdate) {
    var _this = this;
    if (shouldUpdate === void 0) {
      shouldUpdate = true;
    }
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var title = mergedOptions.title,
      footer = mergedOptions.footer,
      path = mergedOptions.path,
      url = mergedOptions.url,
      size = mergedOptions.size,
      loading = mergedOptions.loading;
    this.title = title;
    this.url = url;
    this.path = path;
    this.size = size;
    this.loading = loading;
    this.setFooterPrimaryButton(footer ? footer.buttons.primary : undefined, function () {
      _this.dispatch(Action.UPDATE);
    });
    this.setFooterSecondaryButtons(footer ? footer.buttons.secondary : undefined, function () {
      _this.dispatch(Action.UPDATE);
    });
    if (shouldUpdate) {
      this.dispatch(Action.UPDATE);
    }
    return this;
  };
  ModalIframe.prototype.dispatch = function (action, payload) {
    switch (action) {
      case Action.OPEN:
        this.app.dispatch(openModal(this.payload));
        break;
      case Action.CLOSE:
        this.close();
        break;
      case Action.UPDATE:
        this.app.dispatch(update(this.payload));
        break;
      case Action.DATA:
        this.app.dispatch(data(payload || {}));
        break;
    }
    return this;
  };
  return ModalIframe;
}(Modal);
exports.ModalIframe = ModalIframe;
export default exports;
export const __esModule = exports.__esModule;
const _ModalIframe = exports.ModalIframe,
  _ModalMessage = exports.ModalMessage,
  _Modal = exports.Modal,
  _isMessageModal = exports.isMessageModal,
  _isIframeModal = exports.isIframeModal,
  _data = exports.data,
  _update = exports.update,
  _clickFooterButton = exports.clickFooterButton,
  _updateModalSize = exports.updateModalSize,
  _closeModal = exports.closeModal,
  _openModal = exports.openModal,
  _Size = exports.Size,
  _Action = exports.Action;
export { _ModalIframe as ModalIframe, _ModalMessage as ModalMessage, _Modal as Modal, _isMessageModal as isMessageModal, _isIframeModal as isIframeModal, _data as data, _update as update, _clickFooterButton as clickFooterButton, _updateModalSize as updateModalSize, _closeModal as closeModal, _openModal as openModal, _Size as Size, _Action as Action };